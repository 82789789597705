export default function areArraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    const hashTable = {};
  
    // Count occurrences of objects in arr1
    for (const obj of arr1) {
      const strObj = JSON.stringify(obj);
      hashTable[strObj] = (hashTable[strObj] || 0) + 1;
    }
  
    // Check if each object in arr2 is found in hashTable
    for (const obj of arr2) {
      const strObj = JSON.stringify(obj);
      if (!hashTable[strObj]) {
        return false;
      }
      hashTable[strObj]--;
    }
  
    // Check if all objects in arr2 are accounted for in arr1
    for (const key in hashTable) {
      if (hashTable[key] !== 0) {
        return false;
      }
    }
  
    return true;
  }