import {
    Box,
    Button,
    IconButton,
    Typography,
    Tooltip,
    TextField,
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useRef, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ProgressIndicator from "../../../common/widget/progress_indicator";
import {convertVideosToWebpFiles} from "../../../services/database/database_service";
import CustomAlert from "../../../common/widget/custom_alert";

const VideoToWebpScreen = () => {
    const [showProgressIndicator, setShowProgressIndicator] = useState(false);
    const [filePath, setFilePath] = useState("");
    const [alert, setAlert] = useState({
        show: false,
        severity: "info",
        msg: "",
    });
    // refs
    const videoInputRef = useRef(null);

    const removeSingleRow = (params) => {
        const newRows = rows.filter((row) => row.filename !== params.filename);
        setRows(newRows);
        setShowProgressIndicator(false);
    };

    const handleVideoFileChange = (e) => {
        const files = e.nativeEvent.target.files;
        const tmp = [...rows];
        const filenames = rows.map((e) => e.filename);
        for (let i = 0; i < files.length; i++) {
            // add row if file not already exist
            if (!filenames.includes(files[i].name)) {
                const info = {
                    filename: files[i].name,
                };
                tmp.push(info);
            }
        }
        setRows(tmp);
    };

    const handleDelete = (params) => {
        setShowProgressIndicator(true);
        setAlert({...alert, show: false});
        removeSingleRow(params.row);
    };

    const handleConversion = () => {
        if (filePath !== "") {
            setShowProgressIndicator(true);
            setAlert({...alert, show: false});
            convertVideosToWebpFiles(rows, filePath).then(
                (response) => {
                    setShowProgressIndicator(false);
                    setAlert({
                        show: true,
                        severity: "success",
                        msg: response.data,
                    });
                },
                (error) => {
                    setShowProgressIndicator(false);
                    setAlert({
                        show: true,
                        severity: "error",
                        msg: "Something went wrong",
                    });
                }
            );
        } else {
            setAlert({
                show: true,
                severity: "error",
                msg: "File path is missing",
            });
        }
    };

    const [rows, setRows] = useState([]);
    const columns = [
        {
            field: "filename",
            headerName: "File name",
            headerClassName: 'super-app-theme--header',
            flex: 1,
        },
        {
            field: "path",
            headerName: "Location",
            headerClassName: 'super-app-theme--header',
            flex: 2,
            renderCell: (params) => (
                <Typography>
                    {filePath !== "" ? `${filePath}${params.row.filename}` : ""}
                </Typography>
            ),
        },
        {
            field: "action",
            headerName: "Remove",
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Delete from database">
                    <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDelete(params)}
                    >
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            ),
        },
    ];

    return (
        <Box width={"100%"} px={3} display={"flex"} flexDirection={"column"} gap={2}>
            <CustomAlert alert={alert} setAlert={setAlert}/>
            <Typography color={"grey"}>
                Convert video(s) to Webp file(s)
            </Typography>
            {/* video file pick button */}
            <Box>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => videoInputRef.current.click()}
                    sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
                >
                    Choose video file(s)
                </Button>
                <input
                    ref={videoInputRef}
                    type="file"
                    accept=".mp4"
                    multiple
                    style={{display: "none"}}
                    onChange={handleVideoFileChange}
                />
            </Box>
            <Box display={"flex"} gap={1} width={"70%"}>
                <TextField
                    size="small"
                    label="File path"
                    onChange={(e) => setFilePath(e.target.value)}
                    fullWidth
                    required
                />
                <Button sx={{boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"}} variant="outlined" size="small"
                        onClick={handleConversion}>
                    Convert
                </Button>
            </Box>
            <Box
                sx={{
                    height: '70vh',
                    width: '100%',
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    '& .super-app-theme--header': {
                        backgroundColor: '#ebdcfa',
                    },
                }}
            >
                <DataGrid
                    getRowId={(row) => row.filename}
                    rows={rows}
                    columns={columns}
                />
            </Box>
            {/* progress indicator */}
            <ProgressIndicator show={showProgressIndicator}/>
        </Box>
    );
};

export default VideoToWebpScreen;
