const Space = () => {
  return (
    <>
      <br />
      <br />
    </>
  );
};

export default Space;
