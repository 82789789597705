import { Box, Grid } from "@mui/material";
import { useState } from "react";

const VersionControlScreen = () => {
  const [rows, setRows] = useState([]);
  return (
    <Box p={3}>
      <Grid rows={rows} />
    </Box>
  );
};

export default VersionControlScreen;
