import {
    Box,
    FormControlLabel,
    IconButton,
    Checkbox,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";

import {useState} from "react";
import {getFeedback} from "../../../services/database/database_service";
import ProgressIndicator from "../../../common/widget/progress_indicator";
import CustomAlert from "../../../common/widget/custom_alert";
import {DataGrid} from "@mui/x-data-grid";
import dayjs from "dayjs";

import {formatDate} from "../../../utils/format_date";
import {useFormik} from "formik";
import * as Yup from "yup";

const FeedbackScreen = () => {
    const [isSingleDate, setIsSingleDate] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [specificDate, setSpecificDate] = useState(undefined);
    const [searchType, setSearchType] = useState('range');
    const [dateRange, setDateRange] = useState({
        startDate: undefined,
        endDate: undefined,
    });
    const [showProgressIndicator, setShowProgressIndicator] = useState(false);
    const [feedbackForDialog, setFeedbackForDialog] = useState("");
    const [alert, setAlert] = useState({
        show: false,
        severity: "info",
        msg: "",
    });
    const [rows, setRows] = useState([]);
    const columns = [
        // created at
        {
            field: "$createdAt",
            headerName: "Submitted on",
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => formatDate(params.value),
            renderHeader: (params) => <strong>Submitted on</strong>,
        },
        // feedback
        {
            field: "feedback",
            headerName: "Feedback",
            headerClassName: 'super-app-theme--header',
            flex: 5,
            renderCell: (params) => (
                <Typography
                    onClick={() => {
                        setFeedbackForDialog(params.value);
                        setIsDialogOpen(true);
                    }}
                >
                    {params.value}
                </Typography>
            ),
            renderHeader: (params) => <strong>Feedback</strong>,
        },
    ];

    const rangeDateFormik = useFormik({
        initialValues: {
            startDate: undefined,
            endDate: undefined,
        },
        onSubmit: (values) => handleGetFeedback('range', dateRange),
        validationSchema: Yup.object().shape({
            startDate: Yup.date().required("*Required"),
            endDate: Yup.date().required("*Required"),
        }),
    });

    const specificDateFormik = useFormik({
        initialValues: {
            specificDate: undefined,
        },
        onSubmit: (values) => handleGetFeedback('single', specificDate),
        validationSchema: Yup.object().shape({
            specificDate: Yup.date().required("*Required"),
        }),
    });

    const handleGetFeedback = (type, date) => {
        setShowProgressIndicator(true);
        setRows([]);
        getFeedback(type, date).then(
            (response) => {
                setShowProgressIndicator(false);
                setRows(response.documents);
                setShowProgressIndicator(false);
            },
            (error) => {
                setAlert({show: true, severity: "error", msg: error.response.message});
                // console.log(error.response.message)
                setShowProgressIndicator(false);
            }
        );
    };

    const handleShowAllFeedback = () => {
        setRows([]);
        getFeedback("all").then(
            (response) => {
                try {
                    setRows(response.documents);
                } catch (e) {
                }
            },
            (error) => {
                setAlert({show: true, severity: "error", msg: error});
                setShowProgressIndicator(false);
            }
        );
    };

    const DateRange = () => (
        <Box sx={{display: "flex"}} gap={1}>
            <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* start date */}
                    <DatePicker
                        label="Start"
                        format="DD - MM - YYYY"
                        value={rangeDateFormik.values.startDate}
                        onChange={(value, context) => {
                            setDateRange({
                                ...dateRange,
                                startDate: value,
                            });
                            rangeDateFormik.setFieldValue('startDate', value);
                        }}
                        slotProps={{textField: {size: "small"}}}
                        error={rangeDateFormik.errors.startDate && rangeDateFormik.touched.startDate}
                        helperText={rangeDateFormik.errors.startDate}
                    />
                    <Box display={"flex"} alignItems={"center"} pl={1} pr={1}>
                        -
                    </Box>
                    {/* end date */}
                    <DatePicker
                        // sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
                        slotProps={{textField: {size: "small"}}}
                        disabled={rangeDateFormik.values.startDate === undefined || rangeDateFormik.values.startDate === null}
                        label="End"
                        format="DD - MM - YYYY"
                        value={rangeDateFormik.values.endDate}
                        minDate={dayjs(rangeDateFormik.values.startDate).add(1, "day")}
                        onChange={(value, context) => {
                            setDateRange({...dateRange, endDate: value});
                            rangeDateFormik.setFieldValue('endDate', value);
                        }}
                        error={rangeDateFormik.errors.endDate && rangeDateFormik.touched.endDate}
                        helperText={rangeDateFormik.errors.endDate}
                    />
                </LocalizationProvider>
                <IconButton
                    onClick={()=>{
                        rangeDateFormik.submitForm();
                        // console.log(values)
                    }}
                    sx={{"&:hover": {backgroundColor: "transparent"}}}
                >
                    <SearchIcon/>
                </IconButton>
            </>
        </Box>
    );

    const SingleDate = () => (
        <Box display={"flex"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    value={specificDateFormik.values.specificDate}
                    format="DD - MM - YYYY"
                    // sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
                    onChange={(value, context) => {
                        setSpecificDate(value);
                        specificDateFormik.setFieldValue('specificDate', value)
                    }}
                    slotProps={{
                        textField: {size: "small"},
                    }}
                />
            </LocalizationProvider>
            <IconButton
                // onClick={() => handleGetFeedback("single", specificDate)}
                onClick={specificDateFormik.submitForm}
                sx={{"&:hover": {backgroundColor: "transparent"}}}
            >
                <SearchIcon/>
            </IconButton>
        </Box>
    );

    return (
        <Box p={3} width={"100%"}>
            {/* alert */}
            <CustomAlert alert={alert} setAlert={setAlert}/>
            {/* checkbox, single date & date range */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                }}
                gap={1}
            >
                {/* single date checkbox, show all feedback check */}
                <Box display={"flex"} justifyContent={"space-between"}>
                    {/* single date checkbox */}
                    <FormControlLabel
                        label="Specific date"
                        control={
                            <Checkbox
                                checked={isSingleDate}
                                onChange={() => {
                                    setIsSingleDate(!isSingleDate);
                                }}
                                inputProps={{"aria-label": "controlled"}}
                            />
                        }
                    />
                    <Button size={'small'} onClick={handleShowAllFeedback} variant="outlined">
                        Show all feedback
                    </Button>
                    {/* show all feedback checkbox */}
                    {/* <FormControlLabel
                            label="Show all feedback"
                            control={
                              <Checkbox
                                defaultCheck
                                checked={showAllFeedback}
                                onChange={() => setShowAllFeedback(!showAllFeedback)}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                         /> */}
                </Box>
                {isSingleDate ? <SingleDate/> : <DateRange/>}
                <hr/>
            </Box>
            {/* data-grid */}
            <Box
                sx={{
                    height: '70vh',
                    width: '100%',
                    '& .super-app-theme--header': {
                        backgroundColor: '#ebdcfa',
                    },
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
            >
                <DataGrid rows={rows} columns={columns} getRowId={(row) => row.$id}/>
            </Box>
            {/* progress indicator */}
            <ProgressIndicator show={showProgressIndicator}/>
            {/* dialog */}
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle></DialogTitle>
                <DialogContent>{feedbackForDialog}</DialogContent>
            </Dialog>
        </Box>
    );
};

export default FeedbackScreen;
