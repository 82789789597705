import { Collapse, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Space from "./space";

const CustomAlert = ({ alert, setAlert }) => {
  return (
    <Collapse in={alert.show}>
      <Alert
        severity={alert.severity}
        action={
          <IconButton onClick={() => setAlert({ ...alert, show: false })}>
            <CloseIcon />
          </IconButton>
        }
      >
        {alert.msg}
      </Alert>
      <Space />
    </Collapse>
  );
};

export default CustomAlert;
