import {
    Box,
    Button,
    Container,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";

import Space from "../../common/widget/space";

import PersonIcon from "@mui/icons-material/Person";
import PasswordIcon from "@mui/icons-material/Password";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import {getUser, login} from "../../services/login/auth_service";
import {useNavigate} from "react-router-dom";
import {LoadingIndicator} from "../../common/widget/loading_indicator";
import {AppwriteException} from "appwrite";
import bg2 from "../../assets/background/bg2.png";
import border1 from "../../assets/border/border1.png";
import CustomAlert from "../../common/widget/custom_alert";
import ProgressIndicator from "../../common/widget/progress_indicator";

const LoginScreen = () => {
    const {values, setFieldValue, submitForm, errors, touched} = useFormik({
        initialValues: {
            userID: "",
            password: "",
        },
        onSubmit: (values) => loginAction(),
        validationSchema: Yup.object().shape({
            userID: Yup.string().required("*Required"),
            password: Yup.string().required("*Required"),
        }),
    });

    //   states
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [alert, setAlert] = useState({
        show: false,
        severity: "info",
        msg: "",
    });
    const [showProgressIndicator, setShowProgressIndicator] = useState(false);

    // hooks
    const navigate = useNavigate();

    //   actions
    const loginAction = () => {
        setAlert({...alert, show: false});
        setShowProgressIndicator(true);
        try {
            login(values.userID, values.password).then(
                (response) => {
                    if (response) {
                        navigate("./dashboard/upload-webp");
                    }
                    setShowProgressIndicator(false);
                },
                (error) => {
                    // console.group(error);
                    // name, code, type, response
                    if (error instanceof AppwriteException) {
                        console.log(error.response.message);
                        setAlert({
                            show: true,
                            severity: "error",
                            msg:
                                error.response.message === undefined
                                    ? "Something went wrong"
                                    : error.response.message,
                        });
                    }
                    setShowProgressIndicator(false);
                }
            );
        } catch (e) {
            setAlert({
                show: true,
                severity: "error",
                msg: "Something went wrong",
            });
        }
    };

    useEffect(() => {
        try {
            getUser().then(
                (response) => {
                    // console.log(`response: ${response}`)
                    if (response) {
                        if (!(response instanceof AppwriteException)) {
                            navigate("/dashboard/upload-webp");
                        }
                    }
                    setIsLoading(false);
                },
                (error) => {
                    // console.log(`error: ${error}`)
                    setIsLoading(false);
                }
            );
        } catch (e) {
            // console.log(`error: ${e}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoading ? (
                <LoadingIndicator/>
            ) : (
                <Box
                    sx={{
                        height: "97vh",
                        display: "flex",
                        alignItems: "center",
                        backgroundImage: `url(${bg2})`,
                    }}
                >
                    <Container maxWidth="sm">
                        {/* form */}
                        <Box
                            sx={{
                                p: 2,
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Customize the shadow here
                                backgroundColor: "rgba(255, 255, 255, 0.2)",
                                backdropFilter: "blur(5px)",
                                border: "1px solid rgba(255, 255, 255, 0.3)",
                                borderRadius: "20px",
                                WebkitBackdropFilter: "blur(5px)",
                                fontFamily: "Montserrat",
                            }}
                        >
                            {/* row 1 */}
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <img src={border1} alt=""/>
                                <Typography
                                    align="center"
                                    variant="h3"
                                    sx={{fontFamily: "Montserrat"}}
                                >
                                    Login
                                </Typography>
                                <img
                                    src={border1}
                                    alt=""
                                    style={{transform: "rotate(90deg)"}}
                                />
                            </Box>
                            <Space/>
                            {/* use id & password*/}
                            <Box px={2}>
                                {/* user id */}
                                <TextField
                                    value={values.userID}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    label="User ID"
                                    onChange={(e) => setFieldValue("userID", e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" sx={{mr: 2}}>
                                                <PersonIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={errors.userID && touched.userID}
                                    helperText={errors.userID}
                                    required
                                />
                                <Space/>
                                {/* password */}
                                <TextField
                                    value={values.password}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    onChange={(e) => setFieldValue("password", e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? (
                                                        <VisibilityIcon/>
                                                    ) : (
                                                        <VisibilityOffIcon/>
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start" sx={{mr: 2}}>
                                                <PasswordIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={errors.password && touched.password}
                                    helperText={errors.password}
                                    required
                                />
                            </Box>
                            <Space/>
                            <CustomAlert alert={alert} setAlert={setAlert}/>
                            {/* login button */}
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <img
                                    src={border1}
                                    alt=""
                                    style={{transform: "rotate(270deg)"}}
                                />
                                {/* login button */}
                                <Button variant="outlined" onClick={submitForm}>
                                    Log in
                                </Button>
                                <img
                                    src={border1}
                                    alt=""
                                    style={{transform: "rotate(180deg)"}}
                                />
                            </Box>
                        </Box>
                    </Container>
                    <ProgressIndicator show={showProgressIndicator}/>
                </Box>
            )}
        </>
    );
};

export default LoginScreen;