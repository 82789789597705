import {Account, Client} from "appwrite";

const appEndPoint = process.env.REACT_APP_END_POINT;
const projectId = process.env.REACT_APP_PROJECT_ID;

export const getClient = () => {
    try {
        return new Client()
            .setEndpoint(appEndPoint) // Your API Endpoint
            .setProject(projectId); // Your project ID
    } catch (e) {
        return e;
    }
};

function getAccount(client) {
    return new Account(client);
}

export async function login(email, password) {
    const account = getAccount(getClient());
    return await account.createEmailSession(email, password);
}

export async function logout() {
    const account = new Account(getClient());
    const sessions = await account.listSessions();
    return await account.deleteSession(sessions.sessions[0].$id);
}

export async function getUser() {
    try {
        return await getAccount(getClient()).get();
    } catch (e) {
        return e;
    }
}
