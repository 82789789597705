import {
    Autocomplete,
    Box,
    IconButton,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Tooltip, LinearProgress,
} from "@mui/material";
import {useRef, useState, useEffect} from "react";
import SearchIcon from "@mui/icons-material/Search";
import {DataGrid} from "@mui/x-data-grid";
import {
    getDataByCategory,
    deleteDataInDatabase, updateWepMetadata, getCategories,
} from "../../../services/database/database_service";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from "@mui/icons-material/Delete";
import areArraysEqual from "../../../utils/are_arrays_equal";
import {AppwriteException} from "appwrite";
import ProgressIndicator from "../../../common/widget/progress_indicator";
import CustomAlert from "../../../common/widget/custom_alert";

const DataTablesScreen = () => {
    const [categories, setCategories] = useState([
        "Alphabet",
        "Animals",
        "Capital city",
        "Classroom equipment",
        "Colours",
        "Clothes",
        "Continent",
        "Country",
        "Days of week",
        "Disaster",
        "Dzongkhags",
        "Environment",
        "Family member",
        "Food & drinks",
        "fruits",
        "game",
        "Household items",
        "Job",
        "Ministry",
        "Money",
        "Months",
        "Parts of tree",
        "Place",
        "Seasons",
        "Subjects",
        "Time",
        "Traditional game",
        "Transportation",
        "Vegetables",
    ]);
    const [rows, setRows] = useState([]);
    const [rowsToUpdate, setRowsToUpdate] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const rowsBackup = useRef([]);
    const [showSaveBtn, setShowSaveBtn] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogAudio, setOpenDialogAudio] = useState(false)
    const [webpDetail, setWebpDetail] = useState({webpTitle: "", webpUrls: []});
    const [audioDetail, setAudioDetail] = useState([]);
    const [alert, setAlert] = useState({
        show: false,
        severity: "info",
        msg: "",
    });
    const [isLoadingData, setIsLoadingData] = useState(false);

    const toDeleteFileUrls = useRef([])

    const [showProgressIndicator, setShowProgressIndicator] = useState(false);

    const selectedCategoryRef = useRef("");

    // refs
    const webpInputRef = useRef(null);
    const audioInputRef = useRef(null);

    const columns = [
        // dzTitle
        {
            field: "dzTitle",
            headerName: "Dzongkha title",
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: true,
            renderHeader: (params) => <strong>Dzongkha title</strong>,
        },
        // enTitle
        {
            field: "enTitle",
            headerName: "English title",
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: true,
            renderHeader: (params) => <strong>English title</strong>,
            renderCell: (params) => <Box sx={{textOverflow: 'ellipsis', overflow: 'auto', whiteSpace: 'normal'}}>
                <Typography>{params.row.enTitle}</Typography>
            </Box>
        },
        // webpUrl
        {
            field: "webpUrls",
            headerName: "Webp",
            headerClassName: 'super-app-theme--header',
            flex: 1 / 2,
            renderCell: (params) => (
                <>
                    <img
                        src={params.value[0]}
                        alt="Webp"
                        height={38}
                    />
                    <Tooltip title={'Show more'}>
                        <IconButton
                            onClick={() => {
                                setSelectedRow(params.row);
                                setWebpDetail({
                                    webpTitle: params.id.split(".")[0],
                                    webpUrls: params.value,
                                });
                                setOpenDialog(true);
                            }}>
                            <PreviewIcon/>
                        </IconButton>
                    </Tooltip>
                </>
            ),
            renderHeader: (params) => <strong>Webp</strong>,
        },
        // audioUrl
        {
            field: "audioUrls",
            headerName: "Audio",
            headerClassName: 'super-app-theme--header',
            alignHeader: "center",
            flex: 2,
            renderCell: (params) => (
                <>
                    <audio controls src={params.value[0]}/>
                    <Tooltip title="Show more">
                        <IconButton
                            onClick={() => {
                                setSelectedRow(params.row);
                                setAudioDetail(params.value)
                                setOpenDialogAudio(true);
                            }}
                        >
                            <ChangeCircleIcon/>
                        </IconButton>
                    </Tooltip>
                </>
            ),
            renderHeader: (params) => <strong>Audio</strong>,
        },
        // Delete
        {
            field: "delete",
            headerName: "Delete",
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <Tooltip title="Delete from database">
                    <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDelete(params)}
                    >
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            ),
            renderHeader: (params) => <strong>Delete</strong>,
        },
    ];

    const getData = async () => {
        setRows([])
        setIsLoadingData(true);
        setAlert({...alert, show: false,})
        await getDataByCategory(selectedCategoryRef.current.toLowerCase()).then(
            (response) => {
                setRows(response.documents);
                rowsBackup.current = response.documents;
                if (response.total === 0) {
                    setAlert({
                        show: true,
                        severity: "info",
                        msg: "No content(s) found"
                    })
                }
            },
            (error) => {
                setAlert({
                    show: true,
                    severity: "error",
                    msg: error.response.message,
                })
            }
        );
        setIsLoadingData(false);
    };

    const initializeCategories = async () => {
        await getCategories().then((response)=>{
            const documents = response.documents;
            console.log(response.total)
            let tmpCategory = []
            for(const doc of documents){
                tmpCategory.push(doc.category)
            }
            setCategories(tmpCategory)
        },(error)=>{})
    };
    /**
     * New file addition (audio or webp)
     * @param e - click event
     * @param fileType - webp or mp3
     */
    const handleFileReplacement = (e, fileType) => {
        const replacementFile = e.nativeEvent.target.files[0];
        if (replacementFile !== undefined) {
            // row index of current clicked row
            const rowIndex = rows.findIndex((row) => {
                return row.enTitle === selectedRow.enTitle;
            });
            if (rowIndex !== -1) {
                let newRows;
                if (fileType === "webp") {
                    // add new file url to existing row
                    let _oldUrls = [...rows[rowIndex].webpUrls]
                    let _newUrls = [..._oldUrls,URL.createObjectURL(replacementFile)]

                    newRows = rows.map((row, index) =>
                        index === rowIndex
                            ? {...row, webpUrl: URL.createObjectURL(replacementFile), webpUrls: _newUrls}
                            : row
                    );
                    // add to rowToUpdate
                    let _exist = false
                    let _count = 0
                    let _tmpRowsToUpdate = [...rowsToUpdate]
                    let _newRow = newRows[rowIndex]

                    if(_newRow.hasOwnProperty("webpFiles")){
                        _newRow["webpFiles"] = [..._newRow.webpFiles, replacementFile]
                    }
                    else{
                        _newRow["webpFiles"] = [replacementFile]
                    }

                    for(const r of rowsToUpdate) {
                        if(r.enTitle === selectedRow.enTitle){
                            _exist = true
                            _tmpRowsToUpdate[_count] = _newRow
                            setRowsToUpdate(_tmpRowsToUpdate)
                            break
                        }
                        _count++
                    }
                    if (!_exist) {
                        _tmpRowsToUpdate.push(_newRow)
                        setRowsToUpdate(_tmpRowsToUpdate)
                        setShowSaveBtn(true)
                    }
                }
                else if (fileType === "mp3") {
                    // add new audio file url to existing row
                    let _newAudioUrls = [...rows[rowIndex].audioUrls]
                    _newAudioUrls.push(URL.createObjectURL(replacementFile))
                    newRows = rows.map((row, index) =>
                        index === rowIndex
                            ? {...row, audioUrl: URL.createObjectURL(replacementFile), audioUrls: _newAudioUrls}
                            : row
                    );
                    // add to rowToUpdate
                    let _exist = false
                    let _count = 0
                    let _tmpRowsToUpdate = [...rowsToUpdate]
                    let _newRow = newRows[rowIndex]

                    if(_newRow.hasOwnProperty("audioFiles")){
                        _newRow["audioFiles"] = [..._newRow.audioFiles, replacementFile]
                    }
                    else{
                        _newRow["audioFiles"] = [replacementFile]
                    }

                    for(const r of rowsToUpdate) {
                        if(r.enTitle === selectedRow.enTitle){
                            _exist = true
                            _tmpRowsToUpdate[_count] = _newRow
                            setRowsToUpdate(_tmpRowsToUpdate)
                            break
                        }
                        _count++
                    }

                    if (!_exist) {
                        _tmpRowsToUpdate.push(_newRow)
                        setRowsToUpdate(_tmpRowsToUpdate)
                        setShowSaveBtn(true)
                    }
                    setAudioDetail(_newAudioUrls)
                }

                setRows(newRows);
                // only update webp if change is in webp file
                if (fileType === "webp") {
                    // change dialog webp
                    setWebpDetail({
                        webpTitle: replacementFile.name.split(".")[0],
                        webpUrls: [...webpDetail.webpUrls, URL.createObjectURL(replacementFile)],
                    });
                }
            }
            setShowSaveBtn(true);
        }
    };

    const removeSingleRow = (params) => {
        const newRows = rows.filter((row) => row.enTitle !== params.enTitle);
        setRows(newRows);
    };

    const handleUpdate = async () => {
        setAlert({...alert, show: false})
        setShowSaveBtn(false)
        setShowProgressIndicator(true)
        await updateWepMetadata(rowsToUpdate, toDeleteFileUrls.current).then(
            (response) => {
                setAlert({
                    show: true,
                    severity: "success",
                    msg: "Updated successfully",
                })
            },
            (error) => {
                setAlert({
                    show: true,
                    severity: "error",
                    msg: `Failed to update: ${error}`,
                })
            },
        );
        toDeleteFileUrls.current = []
        setShowProgressIndicator(false);
    };

    function CustomLinearProgress() {
        return (
            <LinearProgress
                style={{backgroundColor: 'white'}} // Change the background color to purple
                color="secondary" // Change the color to secondary (the color you want)
            />
        );
    }

    function onCellEditStop(params, event) {
        const newRow = {...params.row, [params.field]: event.target.value}
        let exist = false;
        let tmpRowsToUpdate = [...rowsToUpdate]
        let count = 0;
        // check if it exists in rowsToUpdate
        for (let r of rowsToUpdate) {
            if (params.id === r.enTitle) {
                exist = true;
                tmpRowsToUpdate[count] = newRow
                setRowsToUpdate(tmpRowsToUpdate)
                setShowSaveBtn(true)
                break
            }
            count++;
        }
        // if doesn't exist then append to list
        if (!exist) {
            tmpRowsToUpdate.push(newRow);
            setRowsToUpdate(tmpRowsToUpdate)
            setShowSaveBtn(true)
        }
    }

    const handleDelete = (params) => {
        setShowProgressIndicator(true);
        setAlert({...alert, show: false});

        deleteDataInDatabase(params.row).then(
            (response) => {
                if (response instanceof AppwriteException) {
                    setAlert({
                        show: true,
                        severity: "error",
                        msg: response.response.message,
                    });
                } else {
                    removeSingleRow(params.row);
                    setAlert({
                        show: true,
                        severity: "success",
                        msg: "Data deleted successfully",
                    });
                }
                setShowProgressIndicator(false);
            },
            (error) => {
                setAlert({show: true, severity: "error", msg: error});
            }
        );
    };

    const handleWebpRemovable=(url)=>{
        let _tmpSelectedRow = {...selectedRow}
        let _tmpUrls = [...webpDetail.webpUrls]
        let _exist = false;
        let _tmpRowsToUpdate = [...rowsToUpdate]
        let _count = 0

        // add to to delete array
        let _tmpToDeleteList = [...toDeleteFileUrls.current];
        if(!_tmpToDeleteList.includes(url)){
            _tmpToDeleteList.push(url)
            toDeleteFileUrls.current = [..._tmpToDeleteList]
        }

        // remove the url
        const _finalTmpUrl = _tmpUrls.filter(u => !u.includes(url))
        const _tmpWebpDetail = {...webpDetail,"webpUrls": _finalTmpUrl}
        setWebpDetail(_tmpWebpDetail)

        _tmpSelectedRow = {..._tmpSelectedRow, "webpUrls": _finalTmpUrl}
        // update rowsToUpdate
        for(let r of rowsToUpdate) {
            if(r.enTitle === selectedRow.enTitle){
                _exist = true
                _tmpRowsToUpdate[_count] = _tmpSelectedRow
                setRowsToUpdate(_tmpRowsToUpdate)
                setShowSaveBtn(true)
                break
            }
            _count++
        }

        if(!_exist){
            _tmpRowsToUpdate.push(_tmpSelectedRow)
            setRowsToUpdate(_tmpRowsToUpdate)
            setShowSaveBtn(true)
        }
        // remove webp url from row
        let _tmpRow = [...rows]
        _count = 0
        for(let r of rows) {
            if(r.enTitle === selectedRow.enTitle) {
                _tmpRow[_count] = _tmpSelectedRow
                break;
            }
            _count++
        }
        setRows(_tmpRow)
    }

    const handleAudioRemovable = (url)=>{
        let _tmpSelectedRow = {...selectedRow}
        let _tmpUrls = [...audioDetail]
        let _exist = false;
        let _tmpRowsToUpdate = [...rowsToUpdate]
        let _count = 0

        // add to to delete array
        let _tmpToDeleteList = [...toDeleteFileUrls.current];
        if(!_tmpToDeleteList.includes(url)){
            _tmpToDeleteList.push(url)
            toDeleteFileUrls.current = [..._tmpToDeleteList]
        }

        let _finalUrl = _tmpUrls.filter(u=>!u.includes(url))
        let _tmpAudioDetail = [..._finalUrl]

        setAudioDetail(_tmpAudioDetail)
        _tmpSelectedRow = {..._tmpSelectedRow, "audioUrls":_finalUrl}

        for(const row of rowsToUpdate) {
            if(row.enTitle === selectedRow.enTitle){
                _exist = true
                _tmpRowsToUpdate[_count] = _tmpSelectedRow
                setRowsToUpdate(_tmpRowsToUpdate)
                setShowSaveBtn(true)
                break
            }
            _count++
        }
        if(!_exist) {
            _tmpRowsToUpdate.push(_tmpSelectedRow)
            setRowsToUpdate(_tmpRowsToUpdate)
            setShowSaveBtn(true)
        }
        // remove webp url from row
        let _tmpRow = [...rows]
        _count = 0
        for(let r of rows) {
            if(r.enTitle === selectedRow.enTitle) {
                _tmpRow[_count] = _tmpSelectedRow
                break;
            }
            _count++
        }
        setRows(_tmpRow)
    }

    useEffect(() => {
        const result = areArraysEqual(rowsBackup.current, rows);
        if (!result) {
            setShowSaveBtn(true);
        } else {
            setShowSaveBtn(false);
        }
    }, [rows, rowsBackup]);

    useEffect(()=>{
        initializeCategories().then();
    },[])

    return (
        <Box p={3} width={"100%"}>
            {/* alert */}
            <CustomAlert alert={alert} setAlert={setAlert}/>
            {/* search field */}
            <Box sx={{display: "flex"}} gap={1}>
                <Autocomplete
                    options={categories}
                    sx={{width: 300}}
                    onChange={(e, newValue) => (selectedCategoryRef.current = newValue)}
                    renderInput={(params) => (
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                            <TextField
                                {...params}
                                label="Category"
                                size="small"
                                required
                                onChange={(e) => (selectedCategoryRef.current = e.target.value)}
                            />
                            <IconButton onClick={getData}>
                                <SearchIcon/>
                            </IconButton>
                        </Box>
                    )}
                />
                {showSaveBtn && rowsToUpdate.length > 0 && (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleUpdate}
                        sx={{boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"}}
                    >
                        Update
                    </Button>
                )}
            </Box>
            <Typography sx={{marginTop: 1, marginBottom: 1}}>Total: {rows.length}</Typography>
            {/* data grid */}
            <Box
                sx={{
                    height: '70vh',
                    width: '100%',
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    '& .super-app-theme--header': {
                        backgroundColor: '#ebdcfa',
                    },
                }}
            >
                <DataGrid
                    getRowId={(row) => row.enTitle}
                    rows={rows}
                    columns={columns}
                    loading={isLoadingData}
                    slots={{
                        loadingOverlay: CustomLinearProgress
                    }}
                    onCellEditStop={onCellEditStop}
                />
            </Box>
            {/* webps & audio input */}
            {/* video input */}
            <input
                ref={webpInputRef}
                type="file"
                accept=".webp"
                style={{display: "none"}}
                onChange={(e) => handleFileReplacement(e, "webp")}
            />
            <input
                ref={audioInputRef}
                type="file"
                accept=".mp3"
                style={{display: "none"}}
                onChange={(e) => handleFileReplacement(e, "mp3")}
            />
            {/* webp */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>
                    <Typography align="center">
                        <strong>{webpDetail.webpTitle} {`- (Number of variant: ${webpDetail.webpUrls.length})`}</strong>
                    </Typography>
                </DialogTitle>
                <DialogContent style={{display: 'flex', gap: 5}}>
                    {
                        webpDetail.webpUrls.map((url)=>{
                            return <Box sx={{border: "1px solid #000", borderRadius: "5px",}}>
                                <img src={url} alt="Webp" height={300} onClick={()=>{}}/>
                                <IconButton onClick={()=>{handleWebpRemovable(url)}}><DeleteIcon/></IconButton>
                            </Box>
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => webpInputRef.current.click()}
                    >
                        Add
                    </Button>
                    <Button size="small" onClick={() => setOpenDialog(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {/* audio */}
            <Dialog open={openDialogAudio} onClose={() => setOpenDialogAudio(false)}>
                <DialogTitle>
                    <Typography align="center">
                        <strong>{webpDetail.webpTitle} {`- (Number of variant: ${audioDetail.length})`}</strong>
                    </Typography>
                </DialogTitle>
                <DialogContent style={{display: 'flex', flexDirection:'column',gap:3}}>
                    {
                        audioDetail.map((url)=>{
                            return <Box gap={3} display={'flex'} alignContent={'center'}>
                                <audio controls src={url}/>
                                <IconButton onClick={()=>{handleAudioRemovable(url)}}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Box>
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => audioInputRef.current.click()}
                    >
                        Add
                    </Button>
                    <Button size="small" onClick={() => setOpenDialogAudio(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {/* process indicator */}
            <ProgressIndicator show={showProgressIndicator}/>
        </Box>
    );
};
export default DataTablesScreen;
