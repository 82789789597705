import {Databases, ID, Query, Storage} from "appwrite";
import {getClient} from "../login/auth_service";
import axios from "axios";

const remoteIP = process.env.REACT_APP_REMOTE_IP;

const projectID = process.env.REACT_APP_PROJECT_ID;
const databaseID = process.env.REACT_APP_DB_ID;

const feedbacksCollectionID = process.env.REACT_APP_FEEDBACKS_COLLECTION_ID
const webpMetadataCollectionID = process.env.REACT_APP_WEBP_METADATA_COLLECTION_ID;
const iterationsCollectionID = process.env.REACT_APP_ITERATIONS_COLLECTION_ID;

const webpBucketID = process.env.REACT_APP_WEBP_BUCKET_ID;
const audioBucketID = process.env.REACT_APP_AUDIO_BUCKET_ID;

const serverIP = "103.252.84.6"

export async function uploadAll(rows) {
    const client = getClient();
    const storage = new Storage(client);
    const databases = new Databases(client);
    let responses = [];

    let categories = [];

    for (let i = 0; i < rows.length; i++) {
        const docID = ID.unique();

        try {
            const [webpUploadResponse, audioUploadResponse] = await Promise.all([
                // upload webp
                storage.createFile(webpBucketID, ID.unique(), rows[i].webpFile),
                // upload audio
                storage.createFile(audioBucketID, ID.unique(), rows[i].audioFile),
            ]);

            const webpFileID = webpUploadResponse.$id;
            const audioFileID = audioUploadResponse.$id;

            const webpUrl = `https://${serverIP}/v1/storage/buckets/${webpBucketID}/files/${webpFileID}/view?project=${projectID}`;
            const audioUrl = `https://${serverIP}/v1/storage/buckets/${audioBucketID}/files/${audioFileID}/view?project=${projectID}`;

            // check if duplicate webp-metadata exist
            // if exist then remove old webp file and audio file
            // then update webpUrl and audioUrl with new webpUrl & audioUrl
            const [doesExist, data] = await duplicateWebpMetadataExist(rows[i].enTitle, databases);

            if (doesExist) {
                const docId = data.documents[0].$id;
                // remove previous webp and audio
                const oldWebpFileId = data.documents[0].webpUrl.split("/")[8]
                const oldAudioFileId = data.documents[0].audioUrl.split("/")[8]
                // to handle exception if the file doesn't exist
                try {
                    await Promise.all([
                        storage.deleteFile(webpBucketID, oldWebpFileId),
                        storage.deleteFile(audioBucketID, oldAudioFileId)
                    ]);
                } catch (e) {
                }

                // update webp metadata
                await databases.updateDocument(
                    databaseID,
                    webpMetadataCollectionID,
                    docId,
                    {
                        enTitle: data.documents[0].enTitle,
                        dzTitle: data.documents[0].dzTitle,
                        category: data.documents[0].category,
                        categories: data.documents[0].categories,
                        webpUrl: webpUrl,
                        audioUrl: audioUrl,
                        webpUrls: [webpUrl], // overwrites old webp
                        audioUrls: [audioUrl], // overwrites old audio
                    }
                );
            }
            else {
                // create new webp metadata
                await databases.createDocument(
                    databaseID,
                    webpMetadataCollectionID,
                    docID,
                    {
                        enTitle: rows[i].enTitle.toLowerCase(),
                        dzTitle: rows[i].dzTitle,
                        category: rows[i].category.toLowerCase(),
                        categories: [rows[i].category.toLowerCase()],
                        webpUrl: webpUrl,
                        audioUrl: audioUrl,
                        webpUrls: [webpUrl],
                        audioUrls: [audioUrl]
                    }
                );
            }

            responses.push({
                [rows[i].filename]: [
                    webpUploadResponse,
                    audioUploadResponse,
                    // metadataUploadResponse,
                ],
            });

            // add category & increment iteration
            if (!categories.includes(rows[i].category)) {
                categories.push(rows[i].category);
                const result = await addCategory(categories[i]);
                if (result[0] === true) {
                    await updateCategory(result[1], databases);
                }
            }
        } catch (error) {
            return error;
        } finally {
        }
    }
    return responses;
}

export async function updateWepMetadata(rows, toDeleteFiles) {
    deleteFiles(toDeleteFiles).then(()=>{})

    const client = getClient();
    const storage = new Storage(client);
    const databases = new Databases(client);
    let _c = 0
    try {
        for (const row of rows) {
            let _webpUrls = [...row.webpUrls]
            let _audioUrls = [...row.audioUrls]
            let _count = 0;
            // check if webUrls or audios are in blob to upload to
            for(const webpUrlOrBlob of row.webpUrls){
                if(webpUrlOrBlob.includes("blob")){
                    try{
                        _webpUrls = _webpUrls.filter((u) => !u.includes("blob"))

                        for(const file of row.webpFiles){
                            const _webpFileID = await storage.createFile(webpBucketID, ID.unique(), file)
                            const urr = `https://${serverIP}/v1/storage/buckets/${webpBucketID}/files/${_webpFileID.$id}/view?project=${projectID}`;
                            _webpUrls = [..._webpUrls, urr]
                        }
                        break;
                    } catch (error){
                        _webpUrls = _webpUrls.filter((u)=>u!==webpUrlOrBlob)
                    }
                }
                _count++
            }
            _count = 0
            for(const audioUrlOrBlob of row.audioUrls) {
                if(audioUrlOrBlob.includes("blob")){
                    try{
                        _audioUrls = _audioUrls.filter(u => !u.includes("blob"))
                        for(const audioFile of row.audioFiles){
                            const _audioFileID = await storage.createFile(audioBucketID, ID.unique(), audioFile)
                            const urrAudio = `https://${serverIP}/v1/storage/buckets/${audioBucketID}/files/${_audioFileID.$id}/view?project=${projectID}`
                            _audioUrls = [..._audioUrls, urrAudio]
                        }
                    } catch (error){
                        _audioUrls = _audioUrls.filter(u => u!==audioUrlOrBlob)
                    }
                }
                _count++
            }
            const data = {
                audioUrl: _audioUrls[0],
                categories: row["categories"],
                category: row["category"],
                dzTitle: row["dzTitle"],
                enTitle: row["enTitle"],
                webpUrl: _webpUrls[0],
                webpUrls: _webpUrls,
                audioUrls: _audioUrls,
            }
            await databases.updateDocument(row["$databaseId"], row["$collectionId"], row["$id"], data)
            _c++
        }
    } catch (error) {
        return error;
    }
}

async function deleteFiles(files) {
    console.log(files)
    const client = getClient();
    const storage = new Storage(client);
    for (const blobOrUrl of files) {
        if (!blobOrUrl.includes("blob")) {
            const url = blobOrUrl.split("/")
            const _buckId = url[6]
            const _fileId = url[8]
            try{
                await storage.deleteFile(_buckId, _fileId)
            }catch(error){}
        }
    }
}

export async function getDataByCategory(category) {
    const databases = new Databases(getClient());
    return await databases.listDocuments(
        databaseID,
        webpMetadataCollectionID,
        [Query.equal("category", category), Query.limit(100)],
    );
}

export async function getOffsetDataByCategory(lastId, category) {
    const databases = new Databases(getClient());
    return await databases.listDocuments(
        databaseID,
        webpMetadataCollectionID,
        [
            Query.equal("category", category),
            Query.limit(100),
            Query.offset(100),
        ],
    );
}

export async function deleteDataInDatabase(row) {
    const docId = row.$id;
    const audioFileId = row.audioUrl.split("files")[1].split("/")[1];
    const webpFileId = row.webpUrl.split("files")[1].split("/")[1];

    const client = getClient();
    const storage = new Storage(client);
    const databases = new Databases(client);

    try {
        return await Promise.all([
            // delete document
            databases.deleteDocument(
                databaseID,
                webpMetadataCollectionID,
                docId
            ),
            // delete webp file
            storage.deleteFile(webpBucketID, webpFileId),
            // delete audio file
            storage.deleteFile(audioBucketID, audioFileId),
        ]);
    } catch (error) {
        return error;
    }
}

export async function getFeedback(type, date) {
    const databases = new Databases(getClient());

    if (type === "single") {
        // start : to avoid one day behind when converting ISO format
        const startOfDay = date.startOf("day").unix();
        const endOfDay = date.endOf("day").unix();

        const startingDay = new Date(startOfDay * 1000)
        const endingDay = new Date(endOfDay * 1000)
        // end

        const startingISODate = startingDay.toISOString();
        const endingISODate = endingDay.toISOString();

        return await databases.listDocuments(
            databaseID,
            feedbacksCollectionID,
            [
                Query.greaterThanEqual("$createdAt", startingISODate),
                Query.lessThanEqual("$createdAt", endingISODate),
                Query.limit(100)
            ]
        );
    } else if (type === "range") {
        // start : to avoid one day behind when converting ISO format
        const startOfDay = date['startDate'].startOf("day").unix();
        const endOfDay = date['endDate'].endOf("day").unix();

        const startingDay = new Date(startOfDay * 1000)
        const endingDay = new Date(endOfDay * 1000)
        // end

        const startingISODate = startingDay.toISOString();
        const endingISODate = endingDay.toISOString();

        return await databases.listDocuments(
            databaseID,
            feedbacksCollectionID,
            [
                Query.greaterThanEqual("$createdAt", startingISODate),
                Query.lessThanEqual("$createdAt", endingISODate),
            ]
        );
    } else if (type === "all") {
        return await databases.listDocuments(
            databaseID,
            feedbacksCollectionID
        );
    }
}

export async function getCategories() {
    const databases = new Databases(getClient());
    try {
        return await databases.listDocuments(
            databaseID,
            iterationsCollectionID,
            [Query.orderAsc("category"), Query.limit(100)]
        );
    } catch (error) {
        return error;
    }
}

export async function addCategory(category, iteration = 1) {
    const databases = new Databases(getClient());
    const [alreadyExist, promise] = await duplicateIterationDocumentExist(category, databases);
    if (!alreadyExist) {
        try {
            return await databases.createDocument(
                databaseID,
                iterationsCollectionID,
                ID.unique(),
                {category: category.toLowerCase(), iteration: iteration}
            );
        } catch (error) {
            return error;
        }
    }
    return [true, promise];
}

async function updateCategory(documents, databases) {
    if (documents.total > 0) {
        const document = documents.documents[0];
        const newVersion = parseInt(document.iteration) + 1;
        await databases.updateDocument(
            databaseID,
            iterationsCollectionID,
            document.$id,
            {iteration: newVersion}
        );
    }
}

export async function deleteCategory(row) {
    const databases = new Databases(getClient());
    try {
        return await databases.deleteDocument(
            databaseID,
            iterationsCollectionID,
            row.$id
        );
    } catch (e) {
        return e;
    }
}

export async function convertVideosToWebpFiles(rows, filePath) {
    let file_paths = [];
    for (const i in rows) {
        const row = rows[i];
        file_paths.push(`${filePath}/${row.filename}`);
    }

    let requestUrl = "https://127.0.0.1:5000/convert";

    file_paths.forEach((path, i) => {
        if (i === 0) {
            requestUrl = requestUrl + `?file_paths=${path}`;
        } else {
            requestUrl = requestUrl + `&file_paths=${path}`;
        }
    });
    return await axios.get(requestUrl);
}

async function duplicateWebpMetadataExist(enTitle, database) {
    const promise = await database.listDocuments(
        databaseID,
        webpMetadataCollectionID,
        [Query.equal("enTitle", enTitle.toLowerCase())]
    );
    return promise.total !== 0 ? [true, promise] : [false, []]
}

async function duplicateIterationDocumentExist(category, database) {
    try {
        const promise = await database.listDocuments(
            databaseID,
            iterationsCollectionID,
            [Query.equal("category", category.toLowerCase())]
        );
        // remove duplicate
        // for (let i in promise.documents) {
        //   database.deleteDocument(
        //     databaseID,
        //     process.env.REACT_APP_CATEGORIES_COLLECTION_ID,
        //     promise.documents[i].$id
        //   );
        // }
        return promise.total !== 0 ? [true, promise] : [false, []];
    } catch (e) {
        return false;
    }
}