import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    TextField, Tooltip,
    Typography,
} from "@mui/material";
import {DataGrid, GridToolbarContainer} from "@mui/x-data-grid";
import Space from "../../../common/widget/space";
import {useEffect, useRef, useState} from "react";
import {bytesToMB} from "../../../utils/bytes_to_mb";
import Checkbox from "@mui/material/Checkbox";
import {getCategories, uploadAll} from "../../../services/database/database_service";
import DeleteIcon from "@mui/icons-material/Delete";
import {AppwriteException} from "appwrite";
import CustomAlert from "../../../common/widget/custom_alert";
import ProgressIndicator from "../../../common/widget/progress_indicator";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const UploadWebpScreen = () => {
    const [rows, setRows] = useState([]);

    const [dialogGif, setDialogGif] = useState(undefined);
    const [dialogGifTitle, setDialogGifTitle] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [showUploadButton, setShowUploadButton] = useState(false);
    const [haveCommonCategory, setHaveCommonCategory] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showProgressIndicator, setShowProgressIndicator] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        severity: "info",
        msg: "",
    });

    const [categories, setCategories] = useState([]);

    const columns = [
        // filename
        {
            field: "filename",
            headerName: "FILENAME",
            headerClassName: 'super-app-theme--header',
            width: 150,
            renderHeader: (params) => <strong>Filename</strong>,
            renderCell: (params) => {
                return <Box sx={{textOverflow: 'ellipsis', overflow: 'auto', whiteSpace: 'normal',}}>
                    <Typography>
                        {params.row.enTitle}
                    </Typography>
                </Box>
            }
        },
        // webp
        {
            field: "webp",
            headerName: "Webp",
            headerClassName: 'super-app-theme--header',
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt=""
                    height={50}
                    onClick={() => handleWebpClick(params)}
                />
            ),
            flex: 1,
            renderHeader: (params) => <strong>Webp</strong>,
        },
        // audio
        {
            field: "audio",
            headerName: "Audio",
            headerClassName: 'super-app-theme--header',
            flex: 2,
            renderHeader: (params) => <strong>Audio</strong>,
            renderCell: (params) => {
                return <Box display={'flex'} width={'100%'}>
                    <audio controls src={params.value}/>
                    <Tooltip title={'Change'}>
                        <IconButton><PublishedWithChangesIcon /></IconButton>
                    </Tooltip>
                </Box>
            },
        },
        // dzTitle
        {
            field: "dzTitle",
            headerName: "Dzongkha title",
            headerClassName: 'super-app-theme--header',
            editable: true,
            flex: 1,
            renderHeader: (params) => <strong>Dzongkha title</strong>,
        },
        // enTitle
        {
            field: "enTitle",
            headerName: "English title",
            headerClassName: 'super-app-theme--header',
            editable: true,
            flex: 1,
            renderHeader: (params) => <strong>English title</strong>,
        },
        // category
        {
            field: "category",
            headerName: "Category",
            headerClassName: 'super-app-theme--header',
            editable: true,
            flex: 1,
            renderHeader: (params) => <strong>Category</strong>,
        },
        // action
        {
            field: "Remove",
            headerName: "Remove",
            headerClassName: 'super-app-theme--header',
            alignHeader: "center",
            align: "center",
            editable: true,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => removeSingleRow(params)}>
                    <DeleteIcon/>
                </IconButton>
            ),
            renderHeader: (params) => <strong>Remove</strong>,
        },
    ];

    // refs
    const videoInputRef = useRef(null);
    const audioInputRef = useRef(null);
    const singleAudioInputRef = useRef(null);

    const audioFileToChangeFor = useRef("")

    const handleWebpClick = (params) => {
        setDialogGifTitle(params.id.split(".")[0]);
        setDialogGif(params.value);
        setOpenDialog(true);
    };

    const handleWebpFileChange = (e) => {
        const files = e.nativeEvent.target.files;
        const tmp = [...rows];
        const len = rows.length;
        const filenames = rows.map((e) => e.filename);
        for (let i = 0; i < files.length; i++) {
            // add row if file not already exist
            if (!filenames.includes(files[i].name)) {
                const info = {
                    slno: len + i + 1,
                    filename: files[i].name,
                    type: files[i].type,
                    webpSize: `${bytesToMB(files[i].size)} MB`,
                    webp: URL.createObjectURL(files[i]),
                    webpFile: files[i],
                    enTitle: files[i].name.split(".")[0],
                    dzTitle: "",
                    category: "",
                };
                tmp.push(info);
            }
        }
        setRows(tmp);
    };

    const handleAudioFileChange = (e) => {
        const files = Array.from(e.nativeEvent.target.files);
        const updatedRows = rows.map((row, i) => {
            const audioFile = files.filter(
                (e) => e.name.split(".")[0] === row.filename.split(".")[0]
            );

            if (audioFile.length > 0) {
                // update the row with audio url and size
                return {
                    ...row,
                    audioFileName: audioFile[0].name,
                    audioFile: audioFile[0],
                    audio: URL.createObjectURL(audioFile[0]),
                    audioSize: `${bytesToMB(audioFile[0].size)} MB`,
                };
            } else {
                return row;
            }
        });
        setRows(updatedRows);
        setShowUploadButton(true);
    };

    // make category of all webp file same
    const handleCommonCategory = (e) => {
        const updatedRows = rows.map((row) => {
            return {
                ...row,
                category: e.target.value.trim(),
            };
        });
        setRows(updatedRows);
    };

    const handleRowSelectionModalChange = (selection) => {
        // console.group(selection);
        const newSelectedRows = rows.filter((row) =>
            selection.includes(row.filename)
        );
        setSelectedRows(newSelectedRows);
    };

    const handleCellValueChange = (newRow, oldRow) => {
        const oldRows = [...rows];
        const index = rows.findIndex((row) => row.filename === oldRow.filename);
        oldRows[index] = newRow;
        setRows(oldRows);
    };

    const isAudioMissing = () => {
        let gifWithMissingAudio = [];
        for (let i = 0; i < rows.length; i++) {
            if (!rows[i].hasOwnProperty("audioFile")) {
                gifWithMissingAudio.push(rows[i]);
            }
        }
        if (gifWithMissingAudio.length > 0) {
            return [true, gifWithMissingAudio];
        }
        return [false, []];
    };

    const isCategoryMissing = () => {
        let gifWithoutCategory = [];
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].category === "") {
                gifWithoutCategory.push(rows[i]);
            }
        }
        if (gifWithoutCategory.length > 0) {
            return [true, gifWithoutCategory];
        }
        return [false, []];
    };

    const upload = () => {
        const [audioMissing, gifWithMissingAudio] = isAudioMissing();
        const [categoryMissing, gifWithMissingCategory] = isCategoryMissing();

        if (!audioMissing && !categoryMissing) {
            setShowProgressIndicator(true);
            setAlert({...alert, show: false}); // close alert if it's open

            uploadAll(rows).then(
                (response) => {
                    setShowProgressIndicator(false);

                    if (response instanceof AppwriteException) {
                        setAlert({
                            show: true,
                            severity: "error",
                            msg: `File upload failed. ${response.response.message}`,
                        });
                    } else {
                        setAlert({
                            show: true,
                            severity: "success",
                            msg: "File uploaded successfully!",
                        });
                    }
                },
                (error) => {
                    setShowProgressIndicator(false);
                    setAlert({
                        show: true,
                        severity: "error",
                        msg: `File upload failed. ${error}`,
                    });
                }
            );
        }
        else {
            if (alert.show) setAlert({...alert, show: false});
            let msgAudio = "";
            let msgCategory = "";

            for (let i = 0; i < gifWithMissingAudio.length; i++) {
                msgAudio = `${msgAudio}${i === 0 ? "" : ", "}${
                    gifWithMissingAudio[i].filename
                }`;
            }

            for (let i = 0; i < gifWithMissingCategory.length; i++) {
                msgCategory = `${msgCategory}${i === 0 ? "" : ", "}${
                    gifWithMissingCategory[i].filename
                }`;
            }

            setAlert({
                show: true,
                severity: "error",
                msg:
                    msgAudio !== ""
                        ? `Audio file missing for ${msgAudio}. ${
                            msgCategory !== "" ? `Category missing for ${msgCategory}` : ""
                        }`
                        : `Category missing for ${msgCategory}`,
            });
        }
    };

    const removeSelectedRows = () => {
        const newRows = rows.filter(
            (row) =>
                !selectedRows.some(
                    (selectedRow) => selectedRow.filename === row.filename
                )
        );
        setRows(newRows);
        setAlert({...alert, show: false})
    };

    const removeAllRows = () => {
        setRows([])
        setAlert({...alert, show: false})
    }

    const removeSingleRow = (params) => {
        const newRows = rows.filter((row) => row.filename !== params.id);
        setRows(newRows);
    };

    function removeRowsToolbar() {
        return (
            <GridToolbarContainer>
                <Button
                    onClick={removeSelectedRows}
                    variant="outlined"
                    color="error"
                    size="small"
                >
                    Remove selected rows
                </Button>
            </GridToolbarContainer>
        );
    }

    const onCellClick = (params, event, detail) => {
        if (params.field === "audio") {
            singleAudioInputRef.current.click()
            audioFileToChangeFor.current = params.id
        }
    }

    function handleSingleAudioPick(e) {
        let temp = []
        const newAudioFile = e.nativeEvent.target.files[0]

        if (e.nativeEvent.target.files.length !== 0) { // prevents exceptions
            for (const row of rows) {
                if (row.filename === audioFileToChangeFor.current) {
                    const info = {
                        ...row,
                        audioFileName: newAudioFile.name,
                        audioFile: newAudioFile,
                        audio: URL.createObjectURL(newAudioFile),
                        audioSize: `${bytesToMB(newAudioFile.size)} MB`
                    }
                    temp.push(info)
                } else {
                    temp.push({...row})
                }
            }
            setShowUploadButton(true)
            setRows(temp)
        }
    }

    const initializeCategories = async () => {
        await getCategories().then((response)=>{
            const documents = response.documents;
            let tmpCategory = []
            for(const doc of documents){
                tmpCategory.push(doc.category)
            }
            setCategories(tmpCategory)
        },(error)=>{})
    };

    useEffect(()=>{
        initializeCategories().then();
    },[])

    return (
        <Box p={3} width={"100%"} height={"90vh"} >
            {/* alert */}
            <CustomAlert alert={alert} setAlert={setAlert}/>
            {/* choose file and upload button */}
            <Box display={"flex"} width={"100%"} gap={2}>
                {/* video input */}
                <input
                    ref={videoInputRef}
                    type="file"
                    accept=".webp"
                    multiple
                    style={{display: "none"}}
                    onChange={handleWebpFileChange}
                />
                {/* multiple audio*/}
                <input
                    ref={audioInputRef}
                    type="file"
                    accept=".mp3"
                    multiple
                    style={{display: "none"}}
                    onChange={handleAudioFileChange}
                />
                {/* single audio pick*/}
                <input
                    ref={singleAudioInputRef}
                    type="file"
                    accept=".mp3"
                    style={{display: "none"}}
                    onChange={handleSingleAudioPick}
                />
                {/* video files upload */}
                <Button
                    variant="outlined"
                    size="small"
                    sx={{boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"}}
                    onClick={() => videoInputRef.current.click()}
                >
                    Choose Webp files
                </Button>
                {/* choose audio files */}
                {rows.length > 0 && (
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"}}
                        onClick={() => audioInputRef.current.click()}
                    >
                        Choose audio files
                    </Button>
                )}
                {rows.length > 0 && showUploadButton && (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={upload}
                        sx={{boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"}}
                    >
                        Upload
                    </Button>
                )}
                {rows.length > 0 && (
                    <Button
                        onClick={removeAllRows}
                        variant="outlined"
                        color="error"
                        size="small"
                    >
                        Remove all
                    </Button>
                )}
            </Box>
            <br/>
            {/* checkbox */}
            {rows.length > 0 && (
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={haveCommonCategory}
                                onChange={(e) => setHaveCommonCategory(e.target.checked)}
                            />
                        }
                        label="All have same category"
                    />
                    {haveCommonCategory && (
                        <Autocomplete
                            options={categories}
                            renderInput={(params)=>(
                                <TextField
                                    {...params}
                                    size="small"
                                    onChange={handleCommonCategory}/>)}
                        />
                    )}
                    <Typography sx={{marginTop: 1, marginBottom: 1}}>Total: {rows.length}</Typography>
                </Box>
            )}
            {/*<Space />*/}
            {/* data grid */}
            <Box
                sx={{
                    height: '70vh',
                    width: '100%',
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    '& .super-app-theme--header': {
                        backgroundColor: '#ebdcfa',
                    },
                }}
            >
                <DataGrid
                    getRowId={(row) => row.filename}
                    rows={rows}
                    columns={columns}
                    experimentalFeatures={{newEditingApi: true}}
                    // checkboxSelection
                    density="comfortable"
                    onRowSelectionModelChange={handleRowSelectionModalChange}
                    // slots={{
                    //     toolbar: selectedRows.length > 1 ? removeRowsToolbar : () => <></>,
                    // }}
                    processRowUpdate={handleCellValueChange}
                    onProcessRowUpdateError={(error) => console.log(`error: ${error}`)}
                    // autoHeight
                    autoPageSize={false} checkboxSelectionVisibleOnly
                    onCellClick={onCellClick}

                />
            </Box>
            {/* webp dialog */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>
                    <Typography align="center">
                        <strong>{dialogGifTitle}</strong>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <img src={dialogGif} alt="GIF" height={300}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>
            {/* progress indicator */}
            <ProgressIndicator show={showProgressIndicator}/>
        </Box>
    );
};

export default UploadWebpScreen;
