import {TextField, Box, Button, IconButton, Typography} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useRef, useState } from "react";
import ProgressIndicator from "../../../common/widget/progress_indicator";
import {
  addCategory,
  deleteCategory,
  getCategories,
} from "../../../services/database/database_service";
import CustomAlert from "../../../common/widget/custom_alert";
import { formatDate } from "../../../utils/format_date";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppwriteException } from "appwrite";
import { removeSingleRow } from "../../../utils/remove_single_row";

const CategoriesScreen = () => {
  const [showProgressIndicator, setShowProgressIndicator] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    severity: "info",
    msg: "",
  });
  const categoryInTextField = useRef("");
  const [rows, setRows] = useState([]);
  const columns = [
    // created at
    {
      field: "$createdAt",
      //   headerName: "Created At",
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderHeader: (params) => <strong>Created At</strong>,
      renderCell: (params) => {
        return formatDate(params.value);
      },
    },
    // category
    {
      field: "category",
      headerName: "Category",
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <strong>Category</strong>,
      flex: 1,
    },
    // version
    {
      field: "iteration",
      headerName: "Iteration",
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderHeader: (params) => <strong>Iteration</strong>,
    },
    // action
    {
      field: "remove",
      headerName: "Remove",
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <strong>Remove</strong>,
      renderCell: (params) => (
          <IconButton onClick={() => removeCategory(params)}>
            <DeleteIcon/>
          </IconButton>
      ),
    },
  ];

  const removeCategory = (params) => {
    setShowProgressIndicator(true);
    setAlert({ ...alert, show: false });
    deleteCategory(params.row).then(
      (response) => {
        if (response instanceof AppwriteException) {
          setAlert({
            show: true,
            severity: "error",
            msg: response.response.message,
          });
        } else {
          const newRows = removeSingleRow(params, rows);
          setRows(newRows);
        }
        setShowProgressIndicator(false);
      },
      (error) => {
        setShowProgressIndicator(false);
        if (error instanceof AppwriteException) {
          setAlert({
            show: true,
            severity: "error",
            msg: error,
          });
        }
      }
    );
  };

  const handleGetCategories = () => {
    setShowProgressIndicator(true);
    getCategories().then(
      (response) => {
        setRows(response.documents);
        setShowProgressIndicator(false);
      },
      (error) => {
        showProgressIndicator(false);
      }
    );
  };

  const handleAddCategory = () => {
    setShowProgressIndicator(true);
    setAlert({ ...alert, show: false });
    addCategory(categoryInTextField.current).then(
      (response) => {
        setShowProgressIndicator(false);
        if (response instanceof AppwriteException) {
          setAlert({
            show: true,
            severity: "error",
            msg: response.response.message,
          });
        } else if (response !== false) {
          setAlert({
            show: true,
            severity: "success",
            msg: "Category added",
          });
          setRows([...rows, response]);
        } else if (response[0] === true) {
          setAlert({ show: true, severity: "info", msg: "Already exist" });
        }
      },
      (error) => {
        setShowProgressIndicator(false);
        setAlert({
          show: true,
          severity: "error",
          msg: "Error: Failed to add category",
        });
      }
    );
  };

  return (
    <Box p={3} width={"100%"}>
      <CustomAlert alert={alert} setAlert={setAlert} />
      <Box display={"flex"} gap={1}>
        <TextField
          // sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
          size="small"
          label="Add category"
          onChange={(e) => (categoryInTextField.current = e.target.value)}
        />
        <Button
          variant="outlined"
          onClick={handleAddCategory}
          sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
        >
          Add
        </Button>
        <Button
          variant="outlined"
          onClick={handleGetCategories}
          sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
        >
          Show all categories
        </Button>
      </Box>
      <br/>
      <Typography>Total: {rows.length}</Typography>
      <br />
      <Box
          sx={{
            height: '70vh',
            width: '100%',
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            '& .super-app-theme--header': {
              backgroundColor: '#ebdcfa',
            },
          }}
      >
        <DataGrid rows={rows} columns={columns} getRowId={(row) => row.$id} />
      </Box>
      <ProgressIndicator show={showProgressIndicator} />
    </Box>
  );
};

export default CategoriesScreen;
//
