import {createBrowserRouter} from "react-router-dom";
import LoginScreen from "../screens/login/login_screen";
import DashboardScreen from "../screens/dashboard/dashboard_screen";
import {getUser} from "../services/login/auth_service";
import DataTablesScreen from "../screens/dashboard/data_tables/DataTablesScreen";
import UploadWebpScreen from "../screens/dashboard/upload_webps/UploadWebpScreen";
import FeedbackScreen from "../screens/dashboard/feedback/FeedbackScreen";
import CategoriesScreen from "../screens/dashboard/categories/categories_screen";
import VersionControlScreen from "../screens/dashboard/version_control/VersionControl";
import VideoToWebpScreen from "../screens/dashboard/video_to_webp/video_to_webp_screen";
import {ReactComponent as MyIcon} from '../assets/svg/Troll-Face.svg'

const ProtectedRoute = ({user, children}) => {
    if (!user) {
        return <LoginScreen/>;
    }
    return children;
};

export const router = createBrowserRouter([
    {path: "/", element: <LoginScreen/>},
    {
        path: "/dashboard",
        element: (
            <ProtectedRoute user={getUser()}>
                <DashboardScreen/>
            </ProtectedRoute>
        ),
        children: [
            {
                path: "upload-webp",
                element: <UploadWebpScreen/>,
            },
            {
                path: "data-tables",
                element: <DataTablesScreen/>,
            },
            {path: "feedback", element: <FeedbackScreen/>},
            {path: "categories", element: <CategoriesScreen/>},
            {path: "version-control", element: <VersionControlScreen/>},
            {path: "video-to-webp", element: <VideoToWebpScreen/>},
            {
                path: "*",
                element: <>Page not found</>,
            },
        ],
    },
    {
        path: "*",
        element: <div style={{display: 'flex'}}>
            {/*<MyIcon/>*/}
            <h1>Page not found</h1>
        </div>
    }
]);
